<template>
    <div>
        <ConfigValues />
    </div>
</template>

<script>
import ConfigValues from "./configValuesView.vue";
export default {
    components: {
        ConfigValues,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
